import React from "react"

export const kfmText = (
  <React.Fragment>
    Wir verstehen uns als kaufmännische Begleiter auf Augenhöhe und geben unsere
    jahrzehntelange Erfahrung gerne an Sie weiter. Wir sind erst zufrieden, wenn
    Sie es auch sind und freuen uns über Ihre Gewinnsteigerung.
  </React.Fragment>
)

export const teamspiritText = (
  <React.Fragment>
    Wir sind ein Team von inspirierenden und unvergleichbaren Menschen, wir
    nutzen für Sie unser hervorragendes Netzwerk- für Ihren Erfolg geht unser
    Team auch gerne die ein oder andere extra Meile.
  </React.Fragment>
)

export const philosophieText = (
  <React.Fragment>
    Bei unserer Arbeit legen wir höchsten Wert auf eine persönliche,
    individuelle und zeitnahe Begleitung. Mit unserer kaufmännischen 360 Grad
    Betrachtung sind unsere Handwerksbetriebe bestens aufgestellt und für die
    Zukunft gewappnet.
  </React.Fragment>
)

export const qualityText = (
  <React.Fragment>
    Mit unserem kompetenten Team achten wir auf die höchste Qualitätsstufe und
    können nachweislich jährlich vielen Handwerksbetrieben zu mehr Gewinn
    verhelfen.
  </React.Fragment>
)

export const whatIsImportantText = (
  <ul>
    <li className="list-style-check">
      <span className="fs-5">
        {" "}
        Wir sind Ihr kaufmännischer Begleiter auf Augenhöhe & geben unser
        Jahrzehnte langes Wissen an Sie weiter
      </span>
    </li>
    <li className="list-style-check">
      <span className="fs-5">
        Wir sind erst dann zufrieden, wenn Sie es sind
      </span>
    </li>
    <li className="list-style-check">
      <span className="fs-5">
        Wir stehlen Ihnen keine Zeit-Wir bringen Ihnen Zeit
      </span>
    </li>
    <li className="list-style-check">
      <span className="fs-5">Mit uns haben Sie Ihre Zahlen im Griff</span>
    </li>
  </ul>
)

export const rainerText = (
  <React.Fragment>
    Gesellschafter FHW360
    <br /> Kaufmännischer Begleiter für das Bauhandwerk <br />
  </React.Fragment>
)
export const martinText = (
  <React.Fragment>
    Gesellschafter FHW360
    <br /> Kaufmännischer Begleiter für das Bauhandwerk <br />
  </React.Fragment>
)
export const stephanText = (
  <React.Fragment>
    Diplom-Volkswirt <br />
    Kaufmännischer Begleiter für das Bauhandwerk <br />
  </React.Fragment>
)
export const jochenText = (
  <React.Fragment>
    Ihr Mann für die pünktliche Bereitstellung Ihrer Reports und unser IT-Profi.
  </React.Fragment>
)
export const thomasText = (
  <React.Fragment>
    Ist verantwortlich für unsere Website und Printmedien. Und die ein oder
    andere Programmierung.
  </React.Fragment>
)
export const melanieText = (
  <React.Fragment>
    Ist die Organisatorin für Seminare/Vorträge und zuständig für die komplette
    Abwicklung von unserem Büro.
  </React.Fragment>
)
export const axelText = (
  <React.Fragment>
    Experte für Neugründung und Unternehmensnachfolge
  </React.Fragment>
)
