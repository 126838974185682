import React from "react"
import Layout from "../components/common/layout"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import HeroSection from "../components/hero-section"
import Video from "../components/common/video"
import {
  Videocontainer,
  BGLinearGradient,
  Separator,
  SeparatorColorFull,
} from "../components/common/styledComponentsHelper"
import {
  kfmText,
  teamspiritText,
  philosophieText,
  qualityText,
  whatIsImportantText,
  rainerText,
  martinText,
  stephanText,
  jochenText,
  thomasText,
  melanieText,
  axelText,
} from "../static/text/ueberUnsContent"
import { videoCaptionText } from "../static/text/commonContent"
import AppointletButton from "../components/common/appointletButton"

export const Head = () => (
  <>
    <title>Über Uns | Fachhandwerk360</title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const UeberUns = () => (
  <Layout>
    <HeroSection
      desktopImg="ueberUns_banner_desktop.png"
      mobileImg="ueberUns_banner_mobile.png"
      headlineText="Über Uns"
      responsiveHeadline="Über Uns"
      subheadlineText="Spezialisten am Werk"
      captionText=""
    />
    <BGLinearGradient>
      <Separator />
      <div className="container text-light">
        <h2 className="text-uppercase text-center fw-bold">
          Mehr als nur{" "}
          <span className="text-success">Kaufmännische Begleiter</span>
        </h2>
        <div className="text-center mt-4 fs-5 col-md-8 col-12 mx-auto">
          {kfmText}
        </div>
      </div>
      <Separator />
      <div className="container">
        <div className="row justify-content-between p-3">
          <div className="col-lg-3 bg-light rounded text-center p-4 shadow mb-4">
            <h3>Teamgeist</h3>
            <SeparatorColorFull />
            <p>{teamspiritText}</p>
          </div>
          <div className="col-lg-3 bg-light rounded text-center p-4 shadow mb-4">
            <h3>Philosophie</h3>
            <SeparatorColorFull />
            <p>{philosophieText}</p>
          </div>
          <div className="col-lg-3 bg-light rounded text-center p-4 shadow mb-4">
            <h3>Qualität</h3>
            <SeparatorColorFull />
            <p>{qualityText}</p>
          </div>
        </div>
      </div>
      <Separator />
      <div className="container-fluid p-0 position-relative">
        <StaticImage
          src="../images/ueber_uns/die_profis.png"
          layout="fullWidth"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Profis"
          placeholder="none"
        />
        <CenterText className="text-center text-light text-uppercase fw-bold">
          Wenn's drauf ankommt, lieber mal die{" "}
          <span className="text-success">Spezialisten machen lassen!</span>
        </CenterText>
      </div>
      <Separator />
      <div className="container">
        <div className="row">
          <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
            <table className="h-100 w-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-end">
                    <h2 className="text-uppercase text-light fw-bold">
                      Wie komme ich zu mehr{" "}
                      <span className="text-success">Gewinn?</span>
                    </h2>
                    <div className="text-light mt-4 fs-5">
                      {videoCaptionText}
                    </div>
                    <br />
                    <AppointletButton />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
            <Videocontainer>
              <Video
                videoSrcURL="https://www.youtube-nocookie.com/embed/zzSkQNJyqPQ?rel=0"
                videoTitle="Die Bierdeckelkalkulation - So funktioniert sie!"
                videoStyle={{
                  top: "0",
                  left: "0",
                  height: "100%",
                }}
              />
            </Videocontainer>
          </div>
        </div>
        <div className="row flex-row-reverse">
          <Separator />
          <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-start">
                    <h2 className="text-uppercase fw-bold text-light">
                      Das ist uns <span className="text-success">wichtig</span>
                    </h2>
                    <div className="text-light">{whatIsImportantText}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
            <StaticImage
              src="../images/ueber_uns/handwerker_handshake.jpg"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Das ist uns wichtig"
              placeholder="none"
            />
          </div>
        </div>
      </div>
      <SeparatorColorFull />
      <div className="container">
        <h2 className="text-center text-uppercase fw-bold text-light">
          Unser <span className="text-success">Team</span>
        </h2>
        <Separator />
        <div className="row">
          <div className="col-lg-3">
            <StaticImage
              src="../images/ueber_uns/rainer.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Rainer"
              placeholder="none"
            />
          </div>
          <div className="col-lg-3 text-light">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-start">
                    <h3 className="text-uppercase fw-bold text-light">
                      <span className="text-success">Rainer Dreier</span>
                    </h3>
                    <p className="text-light fs-5">{rainerText}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-3">
            <StaticImage
              src="../images/ueber_uns/martin.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Martin"
              placeholder="none"
            />
          </div>
          <div className="col-lg-3 text-light">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-start">
                    <h3 className="text-uppercase fw-bold text-light">
                      <span className="text-success">Martin Herber</span>
                    </h3>
                    <p className="text-light fs-5">{martinText}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row flex-lg-row-reverse">
          <div className="col-lg-3">
            <StaticImage
              src="../images/ueber_uns/axel.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Axel"
              placeholder="none"
            />
          </div>
          <div className="col-lg-3 text-light">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-lg-end">
                    <h3 className="text-uppercase fw-bold text-light">
                      <span className="text-success">Axel</span>
                    </h3>
                    <p className="text-light fs-5">{axelText}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-3">
            <StaticImage
              src="../images/ueber_uns/stephan.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Stephan"
              placeholder="none"
            />
          </div>
          <div className="col-lg-3 text-light">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-lg-end">
                    <h3 className="text-uppercase fw-bold text-light">
                      <span className="text-success">Stephan</span>
                    </h3>
                    <p className="text-light fs-5">{stephanText}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <StaticImage
              src="../images/ueber_uns/jochen.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Jochen"
              placeholder="none"
            />
          </div>
          <div className="col-lg-3 text-light">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-lg-end">
                    <h3 className="text-uppercase fw-bold text-light">
                      <span className="text-success">Jochen</span>
                    </h3>
                    <p className="text-light fs-5">{jochenText}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-3">
            <StaticImage
              src="../images/ueber_uns/thomas.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Thomas"
              placeholder="none"
            />
          </div>
          <div className="col-lg-3 text-light">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-start">
                    <h3 className="text-uppercase fw-bold text-light">
                      <span className="text-success">Thomas</span>
                    </h3>
                    <p className="text-light fs-5">{thomasText}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row flex-lg-row-reverse">
          <div className="col-lg-3">
            {/* <StaticImage
              src="../images/ueber_uns/jochen.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Jochen"
              placeholder="none"
            /> */}
          </div>
          <div className="col-lg-3 text-light">
            {/* <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-lg-end">
                    <h3 className="text-uppercase fw-bold text-light">
                      <span className="text-success">Jochen</span>
                    </h3>
                    <p className="text-light fs-5">{jochenText}</p>
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
          <div className="col-lg-3">
            <StaticImage
              src="../images/ueber_uns/melanie.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Melanie"
              placeholder="none"
            />
          </div>
          <div className="col-lg-3 text-light">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle text-start">
                    <h3 className="text-uppercase fw-bold text-light">
                      <span className="text-success">Melanie</span>
                    </h3>
                    <p className="text-light fs-5">{melanieText}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Separator />
    </BGLinearGradient>
  </Layout>
)

const CenterText = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`

export default UeberUns
